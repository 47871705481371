
















































import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import router from "@/router";

export default Vue.extend({
  name: "CandidateDisclaimer",
  data() {
    return {
      illustration: require("@/assets/illustrations/info2.svg"),
      detail_icon: require("@/assets/icons/linear/tick-double.svg")
    };
  },
  async created() {
    // if (!this.get_user_details.is_account_confirmed)
    //   await router.push({
    //     name: "onboarding"
    //   });
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  },
  methods: {
    async proceed_success_page() {
      await router.push({
        name: "onboarding-success"
      });
    }
  }
});
